import React, { lazy, Suspense } from 'react';

const WebPageGoodForVisitor = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'WebPageGoodForVisitorLazy' */ './WebPageGoodForVisitor'));

function WebPageGoodForVisitorLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading WebPageGoodForVisitor ...</div>}>
                <WebPageGoodForVisitor {...props} />
            </Suspense>
        </div>
    );
}

export default WebPageGoodForVisitorLazy;